import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_POST_LOGIN_PAGE,
} from "../constants/common";

interface WithAuthOptions {
  isLoginPage?: boolean;
}

export function withAuthentication<T>(
  WrappedComponent: React.ComponentType<T>,
  options?: WithAuthOptions
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithAuth = (props: T) => {
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    const router = useRouter();
    const translate = useTranslations();

    useEffect(() => {
      const checkAuth = () => {
        const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
        if (options && options.isLoginPage) {
          if (token) {
            toast.error(translate("alreadySignin"));
            router.replace("/");
          } else {
            setIsCheckingAuth(false);
          }
        } else {
          if (token) {
            setIsCheckingAuth(false);
          } else {
            toast.error(translate("mustSignin"));
            sessionStorage.setItem(
              LOCAL_STORAGE_POST_LOGIN_PAGE,
              window.location.pathname
            );
            router.replace("/login");
          }
        }
      };
      checkAuth();
    }, []);

    if (isCheckingAuth) return null;

    return <WrappedComponent {...(props as T)} />;
  };

  ComponentWithAuth.displayName = `withAuthentication(${displayName})`;

  return ComponentWithAuth;
}
