import { GetServerSideProps } from "next";
import { useTranslations } from "next-intl";
import React from "react";
import { toast } from "react-toastify";
import Layout from "../components/common/Layout";
import Container from "../components/container/Container";
import { APP_INFO } from "../constants/common";
import { isGlobalLoadingVar } from "../graphql/cache";
import { withAuthentication } from "../hocs/withAuthentication";
import { LoginWithGoogleInput } from "../resolvers-types";
import { redirectAfterLogin } from "../utils/common";
import Image from "next/image";
import AppLogo from "../public/images/app-logo.png";
import LoginOptions from "../components/loginModal/LoginOptions";
import { useLogGuestUserIn } from "../hooks/useLogGuestUserIn";

function Login() {
  const translate = useTranslations();
  const loginUser = useLogGuestUserIn();

  const logUserIn = async (loginUserInput: LoginWithGoogleInput) => {
    try {
      await loginUser(loginUserInput);
      redirectAfterLogin();
      isGlobalLoadingVar(false);
    } catch (error) {
      toast.error(error.message);
      isGlobalLoadingVar(false);
    }
  };

  return (
    <Layout pageTitle={translate("login") as string}>
      <Container>
        <div className="w-full self-center mt-20 flex flex-col items-center justify-center">
          <div className="flex flex-col items-center">
            <Image
              src={AppLogo}
              alt="me"
              width="80"
              height="80"
              className="rounded-lg"
              priority
              placeholder="blur"
            />
            <div className="font-bold text-xl mt-5">{APP_INFO.appName}</div>
          </div>
          <div className="mt-16 flex flex-col">
            <LoginOptions handleLogUserIn={logUserIn} />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default withAuthentication(Login, { isLoginPage: true });

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      messages: {
        ...require(`../translations/${locale}.json`),
      },
    },
  };
};
